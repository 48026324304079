<template>
  <div class="w-100 object-table">
    <div class="object-table-header">
      <span v-for="key in parsedKeys" :key="key">
        {{ key }}
      </span>
    </div>
    <div class="object-table-body">
      <div class="object-table-row" v-for="(obj, i) in parsedValue" :key="i">
        <button
          class="bg-warning text-white mr-1"
          :class="{ 'cursor-block disabled': i == 0 }"
          @click="i > 0 ? orderUp(i) : () => {}"
        >
          <i class="fas fa-chevron-up"></i>
        </button>
        <button
          class="bg-info text-white mr-1"
          :class="{ 'cursor-block disabled': i == parsedValue.length - 1 }"
          @click="i == parsedValue.length - 1 ? () => {} : orderDown(i)"
        >
          <i class="fas fa-chevron-down"></i>
        </button>
        <div class="obect-table-item" v-for="key in parsedKeys" :key="key">
          <base-colorpicker
            v-if="key == 'cor'"
            class="mb-0 w-100 table-color"
            :color="getStringValue(i, key)"
            :placeholder="getStringValue(i, key)"
            @input="updateFieldValue($event, i, key)"
          />
          <div v-else-if="key == 'imagem'" class="image-icon">
            <i @click="openImage(getStringDefaultValue(i, key))" class="far fa-image collapse-clientes-listagem"></i>
            <!-- <img :src="getStringDefaultValue(i, key)" alt="" style="max-width: 100%"> -->
          </div>
          <div class="d-flex" v-else>
            <input
              @input="updateFieldValue($event, i, key)"
              :value="getStringValue(i, key)"
              type="text"
              :placeholder="key"
              class="border-right-0"
            />

            <div class="d-flex flex-row color-icon"  v-if="hasCrypt">
              <div class="input-group-append ml-0">
                <span class="input-group-text">
                  <i @click="eventoCriptografar()" class="fas fa-lock"></i>
                </span>
              </div>

              <div class="input-group-append ml-0 mr-1">
                <span class="input-group-text">
                  <i @click="eventoDescriptografar()" class="fas fa-unlock"></i>
                </span>
              </div>
              <div class="input-group-append ml-0 mr-1">
                <span class="input-group-text">
                  <i @click="alterarTodosBotaoClickObject()" class="fas fa-list-alt"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <button
          class="bg-danger text-white ml-1"
          :class="{ 'cursor-block': parsedValue.length <= 1 }"
          @click="parsedValue.length > 1 ? removeRow(i) : () => {}"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="object-table-row">
        <div class="obect-table-item">
          <button @click="addRow">Nova linha</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-object',
  data: () => ({}),
  methods: {
  alterarTodosBotaoClickObject() {
    this.$emit('alterarTodosBotaoClickObject')
  },
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    parsedValue() {
      return JSON.parse(this.value);
    },
    parsedKeys() {
      const exObj = this.parsedValue.length ? this.parsedValue[0] : {};
      return Object.keys(exObj).map((k) => k);
    },
  },
  methods: {
     eventoCriptografar() {
      this.$emit('eventoCriptografar')
    },
    eventoDescriptografar() {
      this.$emit('eventoDescriptografar')
    },
    updateFieldValue(evt, index, key) {
      let value = evt.target ? evt.target.value : evt;
      let obj = [...this.parsedValue];
      obj[index][key] = value;
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    getStringValue(index, key) {
      const obj = this.parsedValue[index];
      return obj[key];
    },
    getStringDefaultValue(index, key) {
      let parsedDefaultValue = JSON.parse(this.defaultValue);
      const obj = parsedDefaultValue[index];
      return obj[key];
    },
    addRow(i) {
      let obj = [...this.parsedValue];
      let newO = {};
      this.parsedKeys.forEach((key) => {
        newO[key] = '';
      });
      obj.push(newO);
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    removeRow(i) {
      let obj = [...this.parsedValue];
      obj.splice(i, 1);
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    orderUp(i) {
      let obj = [...this.parsedValue];
      let current = obj[i];
      let aux = obj[i - 1];
      obj[i] = aux;
      obj[i - 1] = current;
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    orderDown(i) {
      let obj = [...this.parsedValue];
      let current = obj[i];
      let aux = obj[i + 1];
      obj[i] = aux;
      obj[i + 1] = current;
      let stringfy = JSON.stringify(obj);
      this.$emit('input', stringfy);
    },
    openImage(img) {
      this.$swal({
        imageUrl: img,
      });
    },
  },
  props: {
    value: {
      type: [String],
      description: 'Input value',
      default: '[]',
    },
    defaultValue: {
      type: [String],
      description: 'Input value',
      default: '[]',
    },
    hasCrypt: {
      type: Boolean,
      description: 'Addon right icon',
      default: false,
      required: false,
    },
  },
};
</script>

<style scoped>
input {
  font-family: FontAwesome, 'Open Sans', Verdana, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
}

.color-icon {
  background-color: #15202b;
}
</style>
