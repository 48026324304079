var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"qr-input"},[_c('div',{staticClass:"form-group",class:[
      { 'has-danger': _vm.error },
      { focused: _vm.focused },
      { 'has-label': _vm.label || _vm.$slots.label },
      { 'has-success': _vm.valid === true },
      { 'has-danger': _vm.valid === false },
      { 'has-value': _vm.hasValue } ]},[_vm._t("label",[(_vm.label)?_c('label',{staticClass:"form-control-label",class:_vm.labelClasses},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',[_vm._v("*")]):_vm._e()]):_vm._e()]),_vm._t("default",[_c('textarea',_vm._g(_vm._b({staticClass:"form-control rounded textarea-height input-bg",class:[
          { 'is-valid': _vm.valid === true },
          { 'is-invalid': _vm.valid === false && _vm.value },
          _vm.inputClasses ],domProps:{"value":_vm.value}},'textarea',_vm.$attrs,false),_vm.listeners))],null,_vm.slotData),_vm._t("helpBlock",[(_vm.error)?_c('div',{staticClass:"text-danger invalid-feedback",staticStyle:{"display":"block"}},[_vm._t("errorBlock")],2):_vm._e()])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }