<template>
  <div class="d-flex flex-row" style="background-color: #ffffff20; height: 2.5rem;"  ref="colorpicker">
    <base-input
      :label="label"
      :addonRightTooltip="tooltipText"
      :addonRightIcon="icon"
      addonLeftIcon="fas fa-circle"
      :addonLeftColor="colorValue"
      type="text"
      class="w-100"
      style="border: none; border-radius: 0px"
      v-model="colorValue"
      @focus="showPicker()"
      @input="updateFromInput"
    />
      <div v-if="isColorPicker">
        <div class="input-group-append ml-0 ">
          <span class="input-group-text">
            <i @click="alterarTodosBotaoClickColorPicker()" class="fas fa-list-alt"></i>
          </span>
          </div>
      </div>
    <chrome-picker
      :value="colors"
      @input="updateFromPicker"
      v-if="displayPicker"
    />
  </div>
</template>

<script>
import VueColor from "vue-color";

const Chrome = VueColor.Chrome;

export default {
  name: "base-colorpicker",
  props: ["color", "label", "tooltipText", "icon", 'isColorPicker'],
  components: { "chrome-picker": Chrome },
  data() {
    return {
      colors: {
        hex: "#000000",
      },
      colorValue: "",
      displayPicker: false,
    };
  },
  mounted() {
    this.setColor(this.color || "#000000");
  },
  methods: {
    alterarTodosBotaoClickColorPicker() {
      this.$emit('alterarTodosBotaoClickColorPicker')
    },
    setColor(color) {
      this.updateColors(color);
      this.colorValue = color;
    },
    updateColors(color) {
      if (color.slice(0, 1) == "#") {
        this.colors = { hex: color };
      } else if (color.slice(0, 4) == "rgba") {
        var rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(","),
          hex =
            "#" +
            (
              (1 << 24) +
              (parseInt(rgba[0]) << 16) +
              (parseInt(rgba[1]) << 8) +
              parseInt(rgba[2])
            )
              .toString(16)
              .slice(1);
        this.colors = {
          hex: hex,
          a: rgba[3],
        };
      }
    },
    showPicker() {
      document.addEventListener("click", this.documentClick);
      this.displayPicker = true;
    },
    hidePicker() {
      document.removeEventListener("click", this.documentClick);
      this.displayPicker = false;
    },
    togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
    },
    updateFromInput() {
      this.updateColors(this.colorValue);
    },
    updateFromPicker(color) {
      this.colors = color;
      if (color.rgba.a == 1) {
        this.colorValue = color.hex;
      } else {
        this.colorValue =
          "rgba(" +
          color.rgba.r +
          ", " +
          color.rgba.g +
          ", " +
          color.rgba.b +
          ", " +
          color.rgba.a +
          ")";
      }
    },
    documentClick(e) {
      var el = this.$refs.colorpicker,
        target = e.target;
      if (el !== target && !el.contains(target)) {
        this.hidePicker();
      }
    },
  },
  watch: {
    colorValue(val) {
      if (val) {
        this.updateColors(val);
        this.$emit("input", val);
      }
    },
  },
};
</script>
